import { Button, Container, Grid, GridItem, VStack } from "@chakra-ui/react";
import { FaAngleRight, FaLightbulb } from "react-icons/fa";

export default function TodolistGuide() {
  return (
    <Container>
      <VStack
        w="100%"
        justifyContent={"center"}
        alignItems={"center"}
        minHeight="calc(100vh - 128px)"
      >
        <Button
          as="a"
          href="https://blog.naver.com/beself_official/223557384855"
          target="_blank"
          w="100%"
          py="25"
        >
          <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
            <GridItem
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <FaLightbulb />
            </GridItem>
            <GridItem display={"flex"} justifyContent={"center"}>
              비셀프 투두리스트 100% 활용법
            </GridItem>
            <GridItem
              fontSize={20}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <FaAngleRight />
            </GridItem>
          </Grid>
        </Button>
        <Button
          as="a"
          href="https://blog.naver.com/beself_official/223557503848"
          target="_blank"
          w="100%"
          py="25"
        >
          <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
            <GridItem
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <FaLightbulb />
            </GridItem>
            <GridItem display={"flex"} justifyContent={"center"}>
              모바일 앱으로 사용하는 방법
            </GridItem>
            <GridItem
              fontSize={20}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <FaAngleRight />
            </GridItem>
          </Grid>
        </Button>
        <Button
          as="a"
          href="https://blog.naver.com/beself_official/223595096977"
          target="_blank"
          w="100%"
          py="25"
        >
          <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
            <GridItem
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <FaLightbulb />
            </GridItem>
            <GridItem display={"flex"} justifyContent={"center"}>
              데스크탑 앱으로 사용하는 방법
            </GridItem>
            <GridItem
              fontSize={20}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <FaAngleRight />
            </GridItem>
          </Grid>
        </Button>
      </VStack>
    </Container>
  );
}
