import {
  Box,
  CircularProgress,
  Collapse,
  Divider,
  Fade,
  Flex,
  HStack,
  Icon,
  Progress,
  ScaleFade,
  Text,
} from "@chakra-ui/react";
import {
  FaAngleDown,
  FaAngleUp,
  FaCheckCircle,
  FaRegCheckCircle,
  FaRegCircle,
} from "react-icons/fa";
import { ITask, ITodoGroup } from "../routes/Todolist";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  completeSubtask,
  getSubTasks,
  ICompleteSubtaskError,
  ICompleteSubtaskSuccess,
  ICompleteSubtaskVariables,
} from "../routes/api";
import SubTaskListSkeleton from "./SubTaskListSkeleton";

interface ITaskItemProps {
  todoGroup: ITodoGroup;
  task: ITask;
  handleOpenModal: (taskPk: number | null, TodoGroupPk: number | null) => void;
  handleCompleteTask: (taskPk: number) => void;
  isDragging: boolean;
  isAnyDragging: boolean;
}

export interface ISubtaskForTaskItem {
  pk: number;
  completed: boolean;
  text: string;
  order: number;
}

export default function TaskItem({
  todoGroup,
  task,
  handleOpenModal,
  handleCompleteTask,
}: ITaskItemProps) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { isLoading: isSubtasksLoading, data: subtasks } = useQuery<
    ISubtaskForTaskItem[]
  >({
    queryKey: ["subtasks", task.pk],
    queryFn: getSubTasks,
    enabled: !isCollapsed,
  });
  const queryClient = useQueryClient();
  const completeSubtaskMutation = useMutation<
    ICompleteSubtaskSuccess,
    ICompleteSubtaskError,
    ICompleteSubtaskVariables
  >({
    mutationFn: completeSubtask,
  });

  useEffect(() => {
    const savedState = localStorage.getItem(`task-${task.pk}-collapsed`);
    if (savedState !== null) {
      setIsCollapsed(JSON.parse(savedState));
    }
  }, [task.pk]);

  useEffect(() => {
    if (task.total_subtasks === 0 && !task.total_subtasks) {
      setIsCollapsed(true);
    }
  }, [task]);

  const toggleCollapse = () => {
    const newState = !isCollapsed;
    setIsCollapsed(newState);
    localStorage.setItem(`task-${task.pk}-collapsed`, JSON.stringify(newState));
  };

  const handleCompleteSubTask = (pk: number) => {
    if (subtasks && pk) {
      let isCompleted = false; // 작업 완료 상태 저장
      const newSubTasks = subtasks.map((subtask) => {
        if (subtask.pk === pk) {
          isCompleted = !subtask.completed; // 작업의 현재 완료 상태 반전
          return { ...subtask, completed: isCompleted };
        }
        return subtask;
      });
      queryClient.setQueryData<ISubtaskForTaskItem[]>(
        ["subtasks", task.pk],
        () => newSubTasks
      );

      // API 호출
      completeSubtaskMutation.mutate(
        { pk, completed: isCompleted },
        {
          onSuccess: (updatedSubtask) => {
            queryClient.setQueryData<ISubtaskForTaskItem[]>(
              ["subtasks", task.pk],
              (oldSubtasks) => {
                if (oldSubtasks) {
                  const filteredSubtasks = oldSubtasks.filter(
                    (Subtask) => Subtask.pk !== updatedSubtask.pk
                  );
                  const newSubTasks = [...filteredSubtasks, updatedSubtask];
                  const orderedSubTasks = newSubTasks.sort(
                    (a, b) => a.order - b.order
                  );
                  return orderedSubTasks;
                }
              }
            );
          },
          onError: () => {
            // 에러 시 이전 상태로 롤백
            queryClient.refetchQueries({ queryKey: ["subtasks", task.pk] });
          },
        }
      );
    }
  };

  const [subtaskProgress, setSubtaskProgress] = useState(0);

  useEffect(() => {
    const calculateSubtaskProgress = () => {
      if (subtasks) {
        const completedSubTasks = subtasks.filter(
          (subtask) => subtask.completed
        ).length;
        return subtasks.length === 0
          ? 0
          : Math.round((completedSubTasks / subtasks.length) * 100);
      }
      return 0;
    };
    setSubtaskProgress(calculateSubtaskProgress());
  }, [subtasks]);

  return (
    <HStack justifyContent={"space-between"} w="100%" alignItems={"flex-start"}>
      <HStack w="100%" alignItems={"flex-start"}>
        <Flex
          onClick={() => handleCompleteTask(task.pk)}
          py={2}
          px={1}
          h="100%"
          cursor="pointer"
          borderRadius="md"
          transition="background-color 0.3s ease"
          justifyContent="center"
          fontSize="large"
        >
          <Icon as={task.completed ? FaCheckCircle : FaRegCircle} />
        </Flex>
        <Box
          onClick={() => handleOpenModal(task.pk, todoGroup.pk)}
          w="100%"
          cursor="pointer"
          borderRadius="md"
          py="2"
        >
          <Text lineHeight={"1"} color={task.completed ? "gray.500" : ""}>
            {task.text}
          </Text>
          {task.condition && (
            <Text lineHeight={"1"} fontSize="sm" color="gray.500" mt="1">
              {task.condition}
            </Text>
          )}
          <Collapse in={!isCollapsed} animateOpacity unmountOnExit>
            <Progress
              mt="2"
              value={subtaskProgress}
              isIndeterminate={isSubtasksLoading}
              colorScheme="green"
              size="sm"
              hasStripe
              min={0}
              max={100}
              borderRadius="lg"
              sx={{
                "& > div:first-of-type": {
                  transitionProperty: "width",
                  transitionDuration: "0.5s",
                  transitionTimingFunction: "ease-in-out",
                },
              }}
            />
            <Flex
              color="gray.500"
              fontSize={"small"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Text>{subtaskProgress}%</Text>
              <Text>
                {subtaskProgress === 0
                  ? "Let's Go!"
                  : subtaskProgress < 40
                  ? "Good!"
                  : subtaskProgress < 70
                  ? "Great!!"
                  : "Excellent!!!"}
              </Text>
            </Flex>
            {isSubtasksLoading ? (
              <>
                <SubTaskListSkeleton />
                <SubTaskListSkeleton />
                <SubTaskListSkeleton />
                <SubTaskListSkeleton />
                <SubTaskListSkeleton />
              </>
            ) : (
              <Box h="100%" color={task.completed ? "gray.500" : ""}>
                {subtasks &&
                  subtasks.map((subtask, index) => (
                    <Box key={index}>
                      <HStack minH="24px" spacing={0}>
                        <Flex
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCompleteSubTask(subtask.pk);
                          }}
                          justifyContent={"center"}
                          pr="2"
                          py="3"
                        >
                          <Icon
                            as={subtask.completed ? FaCheckCircle : FaRegCircle}
                          />
                        </Flex>
                        <Text wordBreak={"break-word"}>{subtask.text}</Text>
                      </HStack>
                      <Divider w="100%" />
                    </Box>
                  ))}
              </Box>
            )}
          </Collapse>
        </Box>
      </HStack>
      {task.total_subtasks !== 0 && task.total_subtasks ? (
        <HStack>
          {isCollapsed && (
            <ScaleFade initialScale={0.5} in={isCollapsed}>
              <Text>{task.total_subtasks}</Text>
            </ScaleFade>
          )}

          <Flex
            fontSize="large"
            cursor="pointer"
            alignItems={"flex-start"}
            py={2}
            pr={1}
            onClick={toggleCollapse}
          >
            <Icon as={isCollapsed ? FaAngleDown : FaAngleUp} />
          </Flex>
        </HStack>
      ) : null}
    </HStack>
  );
}
