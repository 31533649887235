import { Box } from "@chakra-ui/react";
import { Outlet, useLocation, useMatch } from "react-router-dom";
import Header from "./Header";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Footer from "./Footer";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import LoadingScreen from "./LoadingScreen";

export default function Root() {
  const [screenLoading, setScreenLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    // 페이지 이동 시 구글 애널리틱스에 페이지뷰 전송
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  const isQuestionDetailPage = useMatch("/reflection/questions/:id");
  const isUploadExperiencePage = useMatch(
    "/reflection/experiences/positive/upload"
  );
  const isHeaderFooterHidden = isQuestionDetailPage || isUploadExperiencePage;

  useEffect(() => {
    setTimeout(() => {
      setScreenLoading(false); // 페이지 로드 후 1초 뒤 로딩 종료
    }, 1000);
  }, []);

  return (
    <Box>
      <>
        {!isHeaderFooterHidden && <Header />}
        <Box minHeight="calc(100vh - 64px)">
          <Outlet />
        </Box>
        <ReactQueryDevtools />
        {!isHeaderFooterHidden && <Footer />}
      </>
      {screenLoading && (
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100%"
          height="100%"
          zIndex="1000"
        >
          <LoadingScreen />
        </Box>
      )}
    </Box>
  );
}
