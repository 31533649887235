import Cookie from "js-cookie";
import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { ITask, ITodoGroup } from "./Todolist";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? isMobile
        ? "http://172.30.1.85:8000/api/v1/"
        : "http://127.0.0.1:8000/api/v1/"
      : "https://backend.beself.co.kr/api/v1",
  withCredentials: true,
});

export const getTodoGroups = () =>
  instance.get("todo/groups").then((response) => response.data);

export const getTasks = ({ queryKey }: QueryFunctionContext) => {
  const [first, currentGroupPk] = queryKey;
  return instance
    .get(`todo/groups/${currentGroupPk}/tasks`)
    .then((response) => response.data);
};

export const getTask = ({ queryKey }: QueryFunctionContext) => {
  const [first, currentTaskPk] = queryKey;
  return instance
    .get(`todo/tasks/${currentTaskPk}`)
    .then((response) => response.data);
};

export const getSubTasks = ({ queryKey }: QueryFunctionContext) => {
  const [first, currentTaskPk] = queryKey;
  return instance
    .get(`todo/tasks/${currentTaskPk}/subtasks`)
    .then((response) => response.data);
};

export const getMe = () =>
  instance.get(`users/me`).then((response) => response.data);

export const logOut = () =>
  instance
    .post(`users/log-out`, null, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export interface IUsernameLoginVariables {
  username: string;
  password: string;
  turnstile_token: string;
}

export interface IUsernameLoginSuccess {
  ok: string;
}

export interface IUsernameLoginError {
  error: string;
}

export const usernameLogIn = ({
  username,
  password,
  turnstile_token,
}: IUsernameLoginVariables) =>
  instance
    .post(
      `users/log-in`,
      { username, password, turnstile_token },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export interface IEditProfileVariables {
  name: string;
  birth_date: string;
  gender: string;
  is_marketing_agreed: boolean;
}

export interface IEditProfileSuccess {
  ok: string;
}

export interface IEditProfileError {
  error: string[];
}

export const editProfile = ({
  name,
  birth_date,
  gender,
  is_marketing_agreed,
}: IEditProfileVariables) =>
  instance
    .put(
      `users/me`,
      { name, birth_date, gender, is_marketing_agreed },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export interface IChangePasswordVariables {
  old_password: string;
  new_password: string;
  new_password2: string;
}
export interface IChangePasswordSuccess {
  ok: string;
}

export interface IChangePasswordError {
  response: { data: string };
}

export const changePassword = ({
  old_password,
  new_password,
  new_password2,
}: IChangePasswordVariables) =>
  instance
    .put(
      `users/change-password`,
      { old_password, new_password, new_password2 },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export const deleteUser = () =>
  instance
    .delete("users/me", {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export interface ICompleteTaskVariables {
  pk: number;
  completed: boolean;
}

export interface ICompleteTaskSuccess {
  pk: number;
  text: string;
  order: number;
  completed: boolean;
  group: number;
  condition: string;
  total_subtasks: number;
}

export interface ICompleteTaskError {
  error: string[];
}

export const completeTask = ({ pk, completed }: ICompleteTaskVariables) =>
  instance
    .put(
      `todo/update-task-completed/${pk}`,
      { completed },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export interface IUpdateTaskOrderVariables {
  updatedTasks: ITask[];
}

export interface IUpdateTaskOrderSuccess {
  text: string;
}

export interface IUpdateTaskOrderError {
  error: string[];
}

export const updateTaskOrder = ({ updatedTasks }: IUpdateTaskOrderVariables) =>
  instance
    .post(
      `todo/update-tasks-order`,
      {
        tasks: updatedTasks.map((task) => ({
          pk: task.pk,
          order: task.order,
          group: task.group,
        })),
      },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export interface IAddTodoGroupSuccess {
  id: number;
  name: string;
  order: number;
}

export interface IAddTodoGroupError {
  error: string[];
}

export interface IAddTodoGroupVariables {
  name: string;
  order: number;
}

export const addTodoGroup = ({ name, order }: IAddTodoGroupVariables) =>
  instance
    .post(
      `todo/groups`,
      {
        name,
        order,
      },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export interface IDeleteTodoGroupError {
  error: string[];
}

export interface IDeleteTodoGroupVariables {
  todoGroupPk: number;
}

export const deleteTodoGroup = ({ todoGroupPk }: IDeleteTodoGroupVariables) =>
  instance
    .delete(`todo/groups/${todoGroupPk}`, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export interface IUpdateGroupNameVariables {
  pk: number;
  name: string;
}

export interface IUpdateGroupNameSuccess {
  name: string;
}

export interface IUpdateGroupNameError {
  error: string[];
}

export const updateGroupName = ({ pk, name }: IUpdateGroupNameVariables) =>
  instance
    .put(
      `todo/groups/${pk}`,
      { name },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export interface IUpdateTodoGroupsOrderVariables {
  updatedTodoGroups: ITodoGroup[];
}

export interface IUpdateTodoGroupsOrderSuccess {
  text: string;
}

export interface IUpdateTodoGroupsOrderError {
  error: string[];
}

export const updateTodoGroupsOrder = ({
  updatedTodoGroups,
}: IUpdateTodoGroupsOrderVariables) =>
  instance
    .post(
      `todo/update-groups-order`,
      {
        todoGroups: updatedTodoGroups.map((todoGroup) => ({
          pk: todoGroup.pk,
          order: todoGroup.order,
        })),
      },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

interface IAddSubTask {
  completed: boolean;
  text: string;
  order: number;
}

interface IAddTask {
  condition: string;
  order: number;
  text: string;
}

export interface IAddTaskVariables {
  task: IAddTask;
  groupPk: number;
  subtasks: IAddSubTask[];
}

export interface IAddTaskSuccess {
  pk: number;
  completed: boolean;
  group: number;
  text: string;
  order: number;
  condition: string;
  total_subtasks: number;
}

export interface IAddTaskError {
  error: string[];
}

export const addTask = ({ groupPk, task, subtasks }: IAddTaskVariables) =>
  instance
    .post(
      `todo/groups/${groupPk}/tasks`,
      {
        task,
        subtasks,
      },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

interface IUpdateTask {
  text: string;
  condition: string;
}

interface IUpdateSubTask {
  pk: number | null;
  completed: boolean;
  text: string;
  order: number;
}

interface IChangedSubTasks {
  added: IUpdateSubTask[];
  updated: IUpdateSubTask[];
  deleted: number[];
}

export interface IUpdateTaskVariables {
  task: IUpdateTask;
  subtasks: IChangedSubTasks;
  taskPk: number;
}

interface IUpdatedTaskDetail {
  pk: number;
  text: string;
  completed: boolean;
  order: number;
  group: number;
  condition: string;
  total_subtasks: number;
}

interface IUpdatedSubtasksDetail {
  pk: number;
  text: string;
  completed: boolean;
  order: number;
}

export interface IUpdateTaskSuccess {
  task: IUpdatedTaskDetail;
  subtasks: IUpdatedSubtasksDetail[];
}

export interface IUpdateTaskError {
  error: string[];
}

export const updateTask = ({ taskPk, task, subtasks }: IUpdateTaskVariables) =>
  instance
    .put(
      `todo/tasks/${taskPk}`,
      {
        task,
        subtasks,
      },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export interface IDeleteTaskError {
  error: string[];
}

export interface IDeleteTaskVariables {
  taskPk: number;
}

export const deleteTask = ({ taskPk }: IDeleteTaskVariables) =>
  instance
    .delete(`todo/tasks/${taskPk}`, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const getIdentity = () =>
  instance.get("identities/").then((response) => response.data);

export interface IUpdateIdentityVariables {
  pk: number;
  name: string;
}

export interface IUpdateIdentitySuccess {
  name: string;
}

export interface IUpdateIdentityError {
  error: string[];
}

export const updateIdentity = ({ pk, name }: IUpdateIdentityVariables) =>
  instance
    .put(
      `identities/${pk}`,
      { name },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export interface IAddIdentityVariables {
  name: string;
}

export interface IAddIdentitySuccess {
  name: string;
}

export interface IAddIdentityError {
  error: string[];
}

export const addIdentity = ({ name }: IAddIdentityVariables) =>
  instance
    .post(
      `identities/`,
      {
        name,
      },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export interface ISignUpVariables {
  email: string;
  username: string;
  name: string;
  password: string;
  password2: string;
  birth_date: string;
  gender: string;
  is_privacy_and_terms_agreed: boolean;
  is_marketing_agreed: boolean;
  turnstile_token: string;
}

export interface ISignUpSuccess {
  name: string;
}

export interface ISignUpError {
  response: { data: string };
}

export const signUp = ({
  email,
  username,
  name,
  password,
  password2,
  birth_date,
  gender,
  is_privacy_and_terms_agreed,
  is_marketing_agreed,
  turnstile_token,
}: ISignUpVariables) =>
  instance
    .post(
      `users/sign-up/normal`,
      {
        email,
        username,
        name,
        password,
        password2,
        birth_date,
        gender,
        is_privacy_and_terms_agreed,
        is_marketing_agreed,
        turnstile_token,
      },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

interface IUpdatedTask {
  pk: number;
  completed: boolean;
}

export interface IUpdateTasksCompletedVariables {
  tasks: IUpdatedTask[];
}

export interface IUpdateTasksCompletedSuccess {
  text: string;
}

export interface IUpdateTasksCompletedError {
  error: string[];
}

export const updateTasksCompleted = ({
  tasks,
}: IUpdateTasksCompletedVariables) =>
  instance
    .post(
      `todo/update-tasks-completed`,
      {
        tasks,
      },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export const sendVerificationEmail = () =>
  instance
    .post(`users/send-verification-email`, null, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const verifyEmail = ({ queryKey }: QueryFunctionContext) => {
  const [first, token] = queryKey;
  return instance
    .get(`users/verify-email/${token}`)
    .then((response) => response.data);
};

export const kakaoLogin = (code: string) =>
  instance
    .post(
      `users/kakao`,
      { code },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.status);

export interface ISocialSignUpVariables {
  name: string;
  birth_date: string;
  gender: string;
  is_privacy_and_terms_agreed: boolean;
  is_marketing_agreed: boolean;
}

export interface ISocialSignUpSuccess {
  name: string;
}

export interface ISocialSignUpError {
  response: { data: string };
}

export const socialSignUp = ({
  name,
  birth_date,
  gender,
  is_privacy_and_terms_agreed,
  is_marketing_agreed,
}: ISocialSignUpVariables) =>
  instance
    .post(
      `users/sign-up/social`,
      {
        name,
        birth_date,
        gender,
        is_privacy_and_terms_agreed,
        is_marketing_agreed,
      },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export interface ICompleteSubtaskVariables {
  pk: number;
  completed: boolean;
}

export interface ICompleteSubtaskSuccess {
  pk: number;
  text: string;
  order: number;
  completed: boolean;
}

export interface ICompleteSubtaskError {
  error: string[];
}

export const completeSubtask = ({ pk, completed }: ICompleteSubtaskVariables) =>
  instance
    .put(
      `todo/update-subtask-completed/${pk}`,
      { completed },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);
