import {
  Box,
  Button,
  Card,
  CardBody,
  Container,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { FaLock, FaUser, FaUserPlus } from "react-icons/fa";

import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import {
  IUsernameLoginError,
  IUsernameLoginSuccess,
  IUsernameLoginVariables,
  usernameLogIn,
} from "../routes/api";
import SocialLogin from "../components/SocialLogin";
import { Link, useNavigate } from "react-router-dom";
import LoginProtectedPage from "../components/LoginProtectedPage";
import { Turnstile } from "@marsidev/react-turnstile";
import { useEffect, useState } from "react";

interface IForm {
  username: string;
  password: string;
}

export default function Login() {
  const [showTurnstile, setShowTurnstile] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IForm>();
  const navigate = useNavigate();
  const toast = useToast();
  const mutation = useMutation<
    IUsernameLoginSuccess,
    IUsernameLoginError,
    IUsernameLoginVariables
  >({
    mutationFn: usernameLogIn,
    onSuccess: (data) => {
      toast({
        title: `로그인이 완료되었습니다!`,
        description: "어서오세요!",
        status: "success",
        position: "top",
        isClosable: true,
        duration: 3000,
      });
      navigate("/");
    },
  });

  const onSubmit = ({ username, password }: IForm) => {
    if (turnstileToken) {
      mutation.mutate({ username, password, turnstile_token: turnstileToken });
    }
  };

  const watchData = watch();
  useEffect(() => {
    if (watchData.username || watchData.password) {
      setShowTurnstile(true);
    } else {
      setShowTurnstile(false);
    }
  }, [watchData, watch]);

  let turnstileSiteKey;

  if (process.env.NODE_ENV === "development") {
    turnstileSiteKey = "1x00000000000000000000AA"; // 테스트용
  } else {
    turnstileSiteKey = process.env.REACT_APP_TURNSTILE_SITE_KEY;
  }

  return (
    <LoginProtectedPage>
      <VStack
        minH="calc(100vh - 128px)"
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Container maxW="md">
          <VStack mb={5}>
            <Text fontWeight={"700"} fontSize={30}>
              로그인
            </Text>
          </VStack>
          <Card>
            <CardBody as="form" onSubmit={handleSubmit(onSubmit)}>
              <VStack>
                <InputGroup>
                  <InputLeftElement
                    children={
                      <Box color="gray.400">
                        <FaUser />
                      </Box>
                    }
                  />
                  <Input
                    isInvalid={Boolean(errors.username?.message)}
                    {...register("username", {
                      required: "아이디를 입력해주세요",
                    })}
                    variant={"filled"}
                    placeholder="아이디"
                  />
                </InputGroup>
                {errors.username ? (
                  <Text width={"100%"} textAlign={"left"} fontSize={"small"}>
                    {errors.username.message}
                  </Text>
                ) : null}
                <InputGroup>
                  <InputLeftElement
                    children={
                      <Box color="gray.400">
                        <FaLock />
                      </Box>
                    }
                  />
                  <Input
                    isInvalid={Boolean(errors.password?.message)}
                    {...register("password", {
                      required: "패스워드를 입력해주세요",
                    })}
                    type="password"
                    variant={"filled"}
                    placeholder="패스워드"
                  />
                </InputGroup>
                {showTurnstile ? (
                  <Box mt="3">
                    <Turnstile
                      siteKey={turnstileSiteKey}
                      onSuccess={(token) => setTurnstileToken(token)}
                    />
                  </Box>
                ) : null}

                {errors.password ? (
                  <Text width={"100%"} textAlign={"left"} fontSize={"small"}>
                    {errors.password?.message}
                  </Text>
                ) : null}
              </VStack>
              {mutation.isError ? (
                <Text>아이디 또는 패스워드가 유효하지 않습니다.</Text>
              ) : null}

              <Button
                isLoading={mutation.isPending}
                type="submit"
                mt={4}
                colorScheme="red"
                w={"100%"}
              >
                로그인
              </Button>
              <SocialLogin />
            </CardBody>
          </Card>
          <VStack mt="3">
            <Text>계정이 없으신가요?</Text>
            <Link to="/sign-up">
              <Button
                variant={"link"}
                colorScheme="blue"
                leftIcon={<FaUserPlus />}
                w={"100%"}
              >
                회원가입 하러가기 →
              </Button>
            </Link>
          </VStack>
        </Container>
      </VStack>
    </LoginProtectedPage>
  );
}
