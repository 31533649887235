import { createBrowserRouter, Navigate } from "react-router-dom";
import Root from "./components/Root";
import Home from "./routes/Home";
import Todolist from "./routes/Todolist";
import NotFound from "./routes/NotFound";
import EmailVerifiedSuccess from "./routes/EmailVerifiedSuccess";
import EmailVerifiedFailed from "./routes/EmailVerifiedFailed";
import SendVerificationEmail from "./routes/SendVerificationEmail";
import VerifyEmail from "./routes/VerifyEmail";
import Me from "./routes/Me";
import SignUp from "./routes/SignUp";
import NormalSignUp from "./routes/NormalSignUp";
import Login from "./routes/Login";
import KakaoConfirm from "./routes/KakaoConfirm";
import SocialSignUp from "./routes/SocialSignUp";
import Term from "./routes/Term";
import Privacy from "./routes/Privacy";
import Questions from "./routes/Questions";
import QuestionDetail from "./routes/QuestionDetail";
import TodolistGuide from "./routes/TodolistGuide";
import Experiences from "./routes/Experiences";
import PositiveExperiences from "./routes/PositiveExperiences";
import UploadPosExperience from "./routes/UploadPosExperience";
import UploadNegExperience from "./routes/UploadNegExperience";
import NegativeExperiences from "./routes/NegativeExperiences";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <Navigate to="/todolist" replace />,
        // element: <Home />,
      },
      {
        path: "todolist",
        children: [
          { path: "", element: <Todolist /> },
          { path: "guide", element: <TodolistGuide /> },
        ],
      },
      // {
      //   path: "reflection",
      //   children: [
      //     { path: "questions", element: <Questions /> },
      //     { path: "questions/:id", element: <QuestionDetail /> },
      //     { path: "experiences", element: <Experiences /> },
      //     { path: "experiences/positive", element: <PositiveExperiences /> },
      //     {
      //       path: "experiences/positive/upload",
      //       element: <UploadPosExperience />,
      //     },
      //     { path: "experiences/negative", element: <NegativeExperiences /> },
      //     {
      //       path: "experiences/negative/upload",
      //       element: <UploadNegExperience />,
      //     },
      //   ],
      // },
      {
        path: "email-verified-success",
        element: <EmailVerifiedSuccess />,
      },
      {
        path: "email-verified-failed",
        element: <EmailVerifiedFailed />,
      },
      {
        path: "send-verification-email",
        element: <SendVerificationEmail />,
      },
      {
        path: "verify-email/:token",
        element: <VerifyEmail />,
      },
      {
        path: "me",
        element: <Me />,
      },
      {
        path: "sign-up",
        children: [
          { path: "", element: <SignUp /> },
          { path: "normal", element: <NormalSignUp /> },
          { path: "social", element: <SocialSignUp /> },
        ],
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "social",
        children: [
          {
            path: "kakao",
            element: <KakaoConfirm />,
          },
        ],
      },
      {
        path: "term",
        children: [
          {
            path: "",
            element: <Term />,
          },
          {
            path: "privacy",
            element: <Privacy />,
          },
        ],
      },
    ],
  },
]);

export default router;
